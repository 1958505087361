import { useCallback, useEffect, useMemo, useState } from 'react';
import './ProductSales.css';
import ProductSelectionTable from './ProductSelectionTable';
import { Backdrop, CircularProgress, IconButton } from '@mui/material';
import { Add, Cancel, Remove, ShoppingBasket } from '@mui/icons-material';
import Alert from '../shared/Alert/Alert';
import { useOrgOptions } from '../provider/OrganizationOptionsProvider';
import { useGetProducts, useProductSale } from '../provider/RentalSessionsWrapperProvider';
import { useAuth } from '../provider/AuthProvider';
import PaymentMethodInputs from '../shared/PaymentMethodInputs/PaymentMethodInputs';

const emptyResult = [];

function ProductSales({ onSessionSale, setIsOpen, setIsLoading, isLoading }) {
    const [error, setError] = useState();
    const { data: originalProducts = emptyResult } = useGetProducts();
    const { mutateAsync: sale } = useProductSale(setError);
    const [displaySelectedProducts, setDisplaySelectedProducts] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isProductSalesTableOpen, setIsProductSalesTableOpen] = useState(!!onSessionSale);
    const [pricePercent, setPricePercent] = useState(1.0);
    const { currency } = useOrgOptions();
    const { userInfo } = useAuth();
    const [payments, setPayments] = useState({
        cash: 0,
        credit: 0,
    });

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const products = useMemo(() => {
        return originalProducts.map((product) => ({
            ...product,
            price: {
                $numberDecimal: (parseFloat(product.price.$numberDecimal) * pricePercent).toFixed(
                    2
                ),
            },
            name:
                product.name +
                (pricePercent !== 1.0 ? ' -' + (100 - pricePercent * 100) + '%' : ''),
        }));
    }, [originalProducts, pricePercent]);

    const totalPrice = useMemo(() => {
        if (!displaySelectedProducts || !displaySelectedProducts.length) {
            return '0.00';
        }

        return displaySelectedProducts
            .reduce(
                (partialSum, selectedProduct) =>
                    parseFloat(partialSum) +
                    selectedProduct.quantity * parseFloat(selectedProduct.price.$numberDecimal),
                0
            )
            .toFixed(2);
    }, [displaySelectedProducts]);

    const displayProducts = useMemo(() => {
        return products.map((product) => {
            for (let selectedProduct of displaySelectedProducts) {
                if (selectedProduct._id === product._id) {
                    return { ...selectedProduct };
                }
            }

            return { ...product, quantity: 0 };
        });
    }, [products, displaySelectedProducts]);

    useEffect(() => {
        setIsSubmitLoading(!!isLoading);
    }, [isLoading]);

    const toggleProductSalesTable = useCallback(
        (isOpen) => {
            if (onSessionSale) {
                setIsOpen(isOpen);
            } else {
                setIsProductSalesTableOpen(isOpen);
            }
        },
        [setIsProductSalesTableOpen, onSessionSale, setIsOpen]
    );

    const increaseProductQuantity = useCallback(
        (id) => {
            setDisplaySelectedProducts((selectedProducts) => {
                let hasChanged = false;
                const newSelectedProducts = selectedProducts.map((selectedProduct) => {
                    if (id === selectedProduct._id) {
                        hasChanged = true;
                        return { ...selectedProduct, quantity: selectedProduct.quantity + 1 };
                    }

                    return { ...selectedProduct };
                });

                if (!hasChanged) {
                    for (let product of products) {
                        if (id === product._id) {
                            newSelectedProducts.push({ ...product, quantity: 1 });
                            break;
                        }
                    }
                }

                return newSelectedProducts;
            });
        },
        [products]
    );

    const decreaseProductQuantity = useCallback((id) => {
        setDisplaySelectedProducts((selectedProducts) => {
            const newSelectedProducts = selectedProducts.map((selectedProduct) => {
                if (id === selectedProduct._id) {
                    return { ...selectedProduct, quantity: selectedProduct.quantity - 1 };
                }

                return { ...selectedProduct };
            });

            return newSelectedProducts.filter((selectedProduct) => selectedProduct.quantity !== 0);
        });
    }, []);

    const handlePaymentsInput = useCallback(
        (paymentInputs) => {
            setPayments({ ...paymentInputs });
        },
        [setPayments]
    );

    const handleReset = useCallback(() => {
        setDisplaySelectedProducts([]);
        setPricePercent(1.0);
    }, []);

    const handleToggleSale = useCallback((percent) => {
        setDisplaySelectedProducts([]);
        setPricePercent(percent);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (!displaySelectedProducts.length) {
            return;
        }

        if (onSessionSale) {
            setIsLoading(true);

            if (await onSessionSale(displaySelectedProducts)) {
                handleReset();
                setIsSubmitted(false);
                toggleProductSalesTable(false);
            }

            setIsLoading(false);
        } else {
            setIsSubmitLoading(true);

            if (
                await sale({
                    products: displaySelectedProducts,
                    totalPrice,
                    timestamp: Date.now(),
                    paymentCash: payments.cash,
                    paymentCredit: payments.credit,
                })
            ) {
                handleReset();
                setIsSubmitted(false);
            }

            setIsSubmitLoading(false);
        }
    }, [
        displaySelectedProducts,
        onSessionSale,
        setIsLoading,
        handleReset,
        toggleProductSalesTable,
        sale,
        totalPrice,
        payments,
    ]);

    const handleDone = useCallback(() => {
        if (!displaySelectedProducts.length) {
            return;
        }

        setIsSubmitted(true);
    }, [setIsSubmitted, displaySelectedProducts]);

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                Header: ({ column }) => (
                    <div style={{ textAlign: 'left' }}>{column.columnDef.header}</div>
                ),
            },
            {
                header: 'Price',
                id: 'price',
                accessorFn: (dataRow) =>
                    parseFloat(dataRow.price.$numberDecimal).toFixed(2) + currency.sign,
                Cell: ({ cell }) => <div style={{ margin: 'auto' }}>{cell.getValue()}</div>,
            },
            {
                header: 'Quantity',
                accessorKey: 'quantity',
                Cell: ({ cell, row }) => (
                    <div style={{ margin: 'auto' }}>
                        <IconButton color="error" onClick={() => decreaseProductQuantity(row.id)}>
                            <Remove />
                        </IconButton>
                        <span>{cell.getValue()}</span>
                        <IconButton color="success" onClick={() => increaseProductQuantity(row.id)}>
                            <Add />
                        </IconButton>
                    </div>
                ),
            },
        ],
        [increaseProductQuantity, decreaseProductQuantity, currency.sign]
    );

    const selectedColumns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                Header: ({ column }) => (
                    <div style={{ textAlign: 'left' }}>{column.columnDef.header}</div>
                ),
            },
            {
                header: 'Quantity',
                accessorKey: 'quantity',
                Cell: ({ cell, row }) => (
                    <div style={{ margin: 'auto' }}>
                        <IconButton color="error" onClick={() => decreaseProductQuantity(row.id)}>
                            <Remove />
                        </IconButton>
                        <span>{cell.getValue()}</span>
                        <IconButton color="success" onClick={() => increaseProductQuantity(row.id)}>
                            <Add />
                        </IconButton>
                    </div>
                ),
            },
            {
                header: 'Price',
                id: 'price',
                accessorFn: (dataRow) =>
                    (parseFloat(dataRow.price.$numberDecimal) * dataRow.quantity).toFixed(2) +
                    currency.sign,
                Cell: ({ cell }) => <div style={{ margin: 'auto' }}>{cell.getValue()}</div>,
            },
        ],
        [increaseProductQuantity, decreaseProductQuantity, currency.sign]
    );

    return (
        <>
            {isProductSalesTableOpen ? (
                <>
                    <div className="productSales-wrapper">
                        <IconButton
                            style={{ color: 'var(--primary-red)' }}
                            className="closeButton"
                            onClick={() => toggleProductSalesTable(false)}>
                            <Cancel style={{ fontSize: '1.5rem' }} />
                        </IconButton>
                        <ProductSelectionTable
                            columns={columns}
                            products={displayProducts}
                            height={'15rem'}
                            showCloseButton={true}
                            className={'productSelectionTable'}
                        />
                        <div style={{ height: '0.5rem' }}></div>
                        <div
                            style={{
                                backgroundColor: 'var(--primary-color)',
                                color: 'white',
                                borderRadius: '4px',
                            }}>
                            <div
                                style={{
                                    padding: '3px',
                                    fontWeight: '600',
                                    letterSpacing: '1px',
                                    fontSize: '0.7rem',
                                }}>
                                Selected Products
                            </div>
                            <ProductSelectionTable
                                enableToolBar={false}
                                enableHeader={false}
                                columns={selectedColumns}
                                products={displaySelectedProducts}
                                height={'5rem'}
                            />
                            <div
                                className="productSale-recipe"
                                style={{ display: 'flex', flexDirection: 'column' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '0px 20px 0 ',
                                        marginTop: '10px',
                                    }}>
                                    <span style={{ fontWeight: 600, fontSize: '0.8rem' }}>
                                        Total Price:
                                    </span>
                                    <input
                                        disabled
                                        value={totalPrice + currency.sign}
                                        className="total-price-input"
                                    />
                                </div>
                                {onSessionSale ? null : (
                                    <div>
                                        <PaymentMethodInputs
                                            maxTotalValue={totalPrice}
                                            onInput={handlePaymentsInput}
                                            strictMode={true}
                                        />
                                    </div>
                                )}
                                <div className="selectedProducts-buttons">
                                    <button className="popUp-button cancel" onClick={handleReset}>
                                        Reset
                                    </button>

                                    {userInfo.orgId === '65cbe060341c5924ee26c978' ||
                                    userInfo.orgId === '56cb91bdc3464f14678934ca' ||
                                    userInfo.orgId === '6737772126bef3ad71a2918f' ? (
                                        <div className="sale-container">
                                            <button
                                                className="sale-button"
                                                onClick={() => handleToggleSale(0.5)}>
                                                -50%
                                            </button>
                                            <button
                                                className="sale-button"
                                                onClick={() => handleToggleSale(0.0)}>
                                                -100%
                                            </button>
                                        </div>
                                    ) : null}

                                    <button className="popUp-button start" onClick={handleDone}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="productTable-toggle">
                    <IconButton onClick={() => toggleProductSalesTable(true)}>
                        <ShoppingBasket style={{ fontSize: '1.8rem' }} />
                    </IconButton>
                </div>
            )}
            <Alert message={error} setMessage={setError} />

            <Recipe
                isSubmitted={isSubmitted}
                displaySelectedProducts={displaySelectedProducts}
                totalPrice={totalPrice}
                payments={payments}
                setIsSubmitted={setIsSubmitted}
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
                isSessionSale={!!onSessionSale}
            />
        </>
    );
}

export default ProductSales;

function Recipe({
    isSubmitted,
    displaySelectedProducts,
    totalPrice,
    payments,
    setIsSubmitted,
    handleSubmit,
    isLoading,
    isSessionSale,
}) {
    const { currency } = useOrgOptions();

    const handleClose = useCallback(() => {
        if (!isLoading) {
            setIsSubmitted(false);
        }
    }, [setIsSubmitted, isLoading]);

    const selectedColumns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
            },
            {
                header: 'Quantity',
                accessorKey: 'quantity',
                Cell: ({ cell }) => <div style={{ margin: 'auto' }}>{cell.getValue()}</div>,
            },
            {
                header: 'Price',
                id: 'price',
                accessorFn: (dataRow) =>
                    (parseFloat(dataRow.price.$numberDecimal) * dataRow.quantity).toFixed(2) +
                    currency.sign,
                Cell: ({ cell }) => <div style={{ margin: 'auto' }}>{cell.getValue()}</div>,
            },
        ],
        [currency.sign]
    );

    return isSubmitted ? (
        <>
            <Backdrop className="recipe-backdrop" onClick={handleClose} open={true} />
            <div className="recipe-wrapper">
                <h1>Recipe</h1>
                <div className="recipe-selectedProducts">
                    <ProductSelectionTable
                        enableToolBar={false}
                        enableHeader={false}
                        columns={selectedColumns}
                        products={displaySelectedProducts}
                        height={'13rem'}
                    />
                </div>
                <div className="recipe-totalamount">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}>
                        {!isSessionSale ? (
                            <>
                                <span>Cash:</span>
                                <span>Credit:</span>
                            </>
                        ) : null}
                        <span>Total:</span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        {!isSessionSale ? (
                            <>
                                <span>{parseFloat(payments.cash).toFixed(2) + currency.sign}</span>
                                <span>
                                    {parseFloat(payments.credit).toFixed(2) + currency.sign}
                                </span>
                            </>
                        ) : null}
                        <span>{parseFloat(totalPrice).toFixed(2) + currency.sign}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '30px' }}>
                    <button
                        onClick={handleClose}
                        disabled={isLoading}
                        className="popUp-button cancel">
                        Close
                    </button>
                    <button
                        onClick={handleSubmit}
                        disabled={isLoading}
                        className="popUp-button start">
                        Submit
                        {isLoading ? <CircularProgress size={'0.8rem'} color="inherit" /> : null}
                    </button>
                </div>
            </div>
        </>
    ) : null;
}
